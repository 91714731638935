var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import AES from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';
import ls from 'localstorage-slim';
import { APP_STATE, TIME_TO_LIVE } from './local-storage.constants';
export class LocalStorageService {
    initialise() {
        return __awaiter(this, void 0, void 0, function* () {
            const fpPromise = yield FingerprintJS.load();
            const fp = yield fpPromise.get();
            ls.config.encrypt = true;
            ls.config.secret = fp.visitorId;
            // override encrypter and decrypter with AES encryption
            ls.config.encrypter = (data, secret) => {
                return AES.encrypt(JSON.stringify(data), secret).toString();
            };
            ls.config.decrypter = (data, secret) => {
                return JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
            };
        });
    }
    setAppState(appState) {
        if (appState && Object.keys(appState).length > 0) {
            ls.set(APP_STATE, appState, { ttl: TIME_TO_LIVE });
        }
        else {
            this.removeAppState();
        }
    }
    getAppState() {
        return ls.get(APP_STATE) || {};
    }
    removeAppState() {
        ls.remove(APP_STATE);
    }
}
