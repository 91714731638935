/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-vars */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Auth0Client } from '@auth0/auth0-spa-js';
import { WindowRefService } from '../window-ref/window-ref.service';
import { HttpError } from '../http/http-error.model';
import { HttpMethod } from '../http/http.constants';
import { Prompt } from './auth0-sdk.constants';
export class Auth0ClientMock extends Auth0Client {
    constructor(options) {
        super(options);
        this.auth0ClientOptions = options;
        this.windowRefService = new WindowRefService();
    }
    createAuth0Client() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._fetchMock('createAuth0Client');
        });
    }
    isAuthenticated() {
        return __awaiter(this, void 0, void 0, function* () {
            return this._fetchMock('isAuthenticated').then(response => response.isAuthenticated);
        });
    }
    getTokenSilently(options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this._fetchMock('getTokenSilently').then(response => response.accessToken);
        });
    }
    loginWithRedirect(options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            yield this._fetchMock('loginWithRedirect');
            if (((_a = options === null || options === void 0 ? void 0 : options.authorizationParams) === null || _a === void 0 ? void 0 : _a.prompt) === Prompt.LOGIN || !(yield this.isAuthenticated())) {
                this.windowRefService.nativeWindow.location.replace(`http://${this.auth0ClientOptions.domain}/auth0/login`);
                // add a delay to give time to the new ulr to load
                yield new Promise(f => setTimeout(f, 1000));
            }
        });
    }
    logout(options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            yield this._fetchMock('logout');
            if ((_a = options === null || options === void 0 ? void 0 : options.logoutParams) === null || _a === void 0 ? void 0 : _a.returnTo) {
                this.windowRefService.nativeWindow.location.replace(options.logoutParams.returnTo);
                // add a delay to give time to the new ulr to load
                yield new Promise(f => setTimeout(f, 1000));
            }
        });
    }
    handleRedirectCallback(url) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this._fetchMock('handleRedirectCallback'));
        });
    }
    getUser() {
        return __awaiter(this, void 0, void 0, function* () {
            return this._fetchMock('getUser').then(response => response.user);
        });
    }
    getIdTokenClaims() {
        return __awaiter(this, void 0, void 0, function* () {
            return this._fetchMock('getIdTokenClaims').then(response => response.claims);
        });
    }
    _fetchMock(path) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`http://${this.auth0ClientOptions.domain}/auth0/${path}`, { method: HttpMethod.GET });
            if (!response.ok) {
                throw yield HttpError.createHttpError(response);
            }
            return response.json().catch(() => ({}));
        });
    }
}
