export var Domain;
(function (Domain) {
    Domain["PROD"] = "welcome.auspost.com.au";
    Domain["SANDBOX"] = "welcome.sandbox.auspost.com.au";
    Domain["VTEST"] = "welcome.vtest.auspost.com.au";
    Domain["STEST"] = "welcome.stest.auspost.com.au";
    Domain["PTEST"] = "welcome.ptest.auspost.com.au";
    Domain["DEV"] = "welcome.dev.auspost.com.au";
    Domain["PLAYPEN"] = "welcome.playpen.auspost.com.au";
    Domain["LOCALHOST"] = "localhost:8080";
})(Domain || (Domain = {}));
export var Prompt;
(function (Prompt) {
    Prompt["NONE"] = "none";
    Prompt["CONSENT"] = "consent";
    Prompt["LOGIN"] = "login";
    Prompt["SELECT_ACCOUNT"] = "select_account";
})(Prompt || (Prompt = {}));
export const GLOBAL_CSSO_AUDIENCE = 'https://digitalapi.auspost.com.au/cssoapi/v2';
export const AUTH0_ERROR_LOGIN_REQUIRED = 'login_required';
export const AUTH0_ERROR_REAUTH_REQUIRED = 'reauth-required';
export const AUTH0_ERROR_INVALID_GRANT = 'invalid_grant';
