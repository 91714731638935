var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { Env } from '../auth-web/models/auth-web-config.model';
import { openUrlInIframe } from '../../utils/utils';
import { Auth0ClientMock } from './auth0-client.mock';
export class Auth0SdkService {
    get auth0Client() {
        if (!this._auth0Client) {
            throw new Error('auth0Client not defined');
        }
        return this._auth0Client;
    }
    set auth0Client(auth0Client) {
        this._auth0Client = auth0Client;
    }
    initialise(options, skipAuth0SdkMock = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!skipAuth0SdkMock && options.domain.startsWith(Env.LOCALHOST)) {
                    console.info('running in LOCALHOST with MOCK');
                    this.auth0Client = new Auth0ClientMock(options);
                    yield this.auth0Client.createAuth0Client();
                }
                else {
                    this.auth0Client = yield createAuth0Client(Object.assign(Object.assign({}, options), { useRefreshTokens: true, useRefreshTokensFallback: true, useCookiesForTransactions: true }));
                }
            }
            catch (error) {
                console.warn('Error when initialising Auth0 Client', error.message);
                throw error;
            }
        });
    }
    isAuthenticated() {
        return this.auth0Client.isAuthenticated();
    }
    getAccessTokenSilently(options) {
        return this.auth0Client.getTokenSilently(options);
    }
    login(options) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.auth0Client.loginWithRedirect(options);
            // wait promise resolve to ensure login redirect finished
            return new Promise(resolve => setTimeout(resolve, 1000));
        });
    }
    logout(url, skipRedirect, clientId) {
        return __awaiter(this, void 0, void 0, function* () {
            const options = skipRedirect
                ? {
                    openUrl: openUrlInIframe,
                    logoutParams: { returnTo: url }
                }
                : { logoutParams: { returnTo: url } };
            if (clientId || clientId === null)
                options.clientId = clientId;
            yield this.auth0Client.logout(options);
        });
    }
    handleRedirectCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.auth0Client.handleRedirectCallback();
        });
    }
    getUser() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.auth0Client.getUser();
        });
    }
    getIdTokenClaims() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.auth0Client.getIdTokenClaims();
        });
    }
}
