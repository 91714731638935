export const ERROR_LOGIN_REQUIRED_NAME = 'login_required';
export const ERROR_REAUTH_REQUIRED_NAME = 'reauth-required';
export const ERROR_INVALID_GRANT_REQUIRED_NAME = 'invalid_grant';
export const ERROR_LOGIN_REQUIRED_DESCRIPTION = 'Login required';
export const ERROR_REAUTH_REQUIRED_DESCRIPTION = 'Reauth required';
export const ERROR_INVALID_GRANT_REQUIRED_DESCRIPTION = 'Invalid refresh token.';
export class AuthError extends Error {
    constructor(error, error_description) {
        super(error_description);
        this.error = error;
        this.error_description = error_description;
        // needed because otherwise instanceof does not work when transpiling to ES5
        Object.setPrototypeOf(this, AuthError.prototype);
    }
    static createLoginRequiredError() {
        return new AuthError(ERROR_LOGIN_REQUIRED_NAME, ERROR_LOGIN_REQUIRED_DESCRIPTION);
    }
    static createReauthRequiredError() {
        return new AuthError(ERROR_REAUTH_REQUIRED_NAME, ERROR_REAUTH_REQUIRED_DESCRIPTION);
    }
    static createInvalidGrantError() {
        return new AuthError(ERROR_INVALID_GRANT_REQUIRED_NAME, ERROR_INVALID_GRANT_REQUIRED_DESCRIPTION);
    }
    isLoginRequiredError() {
        return this.error === ERROR_LOGIN_REQUIRED_NAME;
    }
    isReauthRequiredError() {
        return this.error === ERROR_REAUTH_REQUIRED_NAME;
    }
    isInvalidGrantError() {
        return this.error === ERROR_INVALID_GRANT_REQUIRED_NAME;
    }
}
