import { Env } from '../auth-web/models/auth-web-config.model';
import { Domain } from '../auth0-sdk/auth0-sdk.constants';
export const ENV_DOMAIN_MAPPING = {
    [Env.PROD]: Domain.PROD,
    [Env.SANDBOX]: Domain.SANDBOX,
    [Env.VTEST]: Domain.VTEST,
    [Env.STEST]: Domain.STEST,
    [Env.PTEST]: Domain.PTEST,
    [Env.DEV]: Domain.DEV,
    [Env.PLAYPEN]: Domain.PLAYPEN,
    [Env.LOCALHOST]: Domain.LOCALHOST
};
export const DEFAULT_SCOPE = 'email profile';
