import { Env } from './models/auth-web-config.model';
export var WebBaseUrl;
(function (WebBaseUrl) {
    WebBaseUrl["PROD"] = "https://auspost.com.au";
    WebBaseUrl["SANDBOX"] = "https://ptest.npe.auspost.com.au";
    WebBaseUrl["VTEST"] = "https://vtest.npe.auspost.com.au";
    WebBaseUrl["STEST"] = "https://stest.npe.auspost.com.au";
    WebBaseUrl["PTEST"] = "https://ptest.npe.auspost.com.au";
    WebBaseUrl["DEV"] = "https://pdev.npe.auspost.com.au";
    WebBaseUrl["PLAYPEN"] = "https://ptest.npe.auspost.com.au";
    WebBaseUrl["LOCALHOST"] = "http://localhost:4000";
})(WebBaseUrl || (WebBaseUrl = {}));
export const ENV_WEB_BASE_URL_MAPPING = {
    [Env.PROD]: WebBaseUrl.PROD,
    [Env.SANDBOX]: WebBaseUrl.SANDBOX,
    [Env.VTEST]: WebBaseUrl.VTEST,
    [Env.STEST]: WebBaseUrl.STEST,
    [Env.PTEST]: WebBaseUrl.PTEST,
    [Env.DEV]: WebBaseUrl.DEV,
    [Env.PLAYPEN]: WebBaseUrl.PLAYPEN,
    [Env.LOCALHOST]: WebBaseUrl.LOCALHOST
};
export const CUSTOMER_SETUP_PATH = '/customersetup';
export const DEPRECIATION_WARNING_MESSAGE = '!!! WARNING: this method of calling logout with the [url], [skipRedirect] parameters is considered legacy and will be removed in future UIIM versions. Please refer to the UIIM docs and make the necessary to changes your application !!!';
export const LOGOUT_TYPES = {
    AUTH0: 'AUTH0',
    OIDC: 'OIDC'
};
export const LOGOUT_URLS = {
    AUTH0: '/v2/logout',
    OIDC: '/oidc/logout'
};
export const REDIRECT_WHITE_LIST = [
    'TRACK',
    'CYCL',
    'ONLINE_SHOP_AP',
    'ONLINE_SHOP_ST',
    'AEM_AUS_POST_WEBSITE',
    'CONTEXTUAL_HELP' // Mando
];
export const COOKIE_EXPIRE = '=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
