import 'promise/polyfill';
import { AuthWebService } from './services/auth-web/auth-web.service';
import { WindowRefService } from './services/window-ref/window-ref.service';
import { ServiceFactory } from './services/service.factory';
export class Main {
    constructor() {
        this.windowRefService = new WindowRefService();
    }
    initialise() {
        this.windowRefService.nativeWindow.__AUSPOST__.createAuthWebService = () => new ServiceFactory().getService(AuthWebService.name);
    }
}
new Main().initialise();
