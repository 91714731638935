var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Auth0SdkService } from '../auth0-sdk/auth0-sdk.service';
import { WindowRefService } from '../window-ref/window-ref.service';
import { CUSTOMER_SETUP_PATH, ENV_WEB_BASE_URL_MAPPING } from '../auth-web/auth-web.constants';
export class ReauthService {
    constructor(serviceFactory) {
        this.serviceFactory = serviceFactory;
        this.auth0SdkService = serviceFactory.getService(Auth0SdkService.name);
        this.windowRefService = serviceFactory.getService(WindowRefService.name);
    }
    reauthenticate(config) {
        return __awaiter(this, void 0, void 0, function* () {
            if (config === null || config === void 0 ? void 0 : config.stepUpOnReauth) {
                const base64EncodedConfig = window.btoa(JSON.stringify(config));
                const urlEncodedBase64Config = encodeURIComponent(base64EncodedConfig);
                this.windowRefService.nativeWindow.location.replace(`${ENV_WEB_BASE_URL_MAPPING[config.env]}${CUSTOMER_SETUP_PATH}/session/reauth?config=${urlEncodedBase64Config}`);
                return Promise.resolve();
            }
            else {
                yield this.auth0SdkService.login({
                    authorizationParams: Object.assign(Object.assign(Object.assign({ max_age: 0, prompt: 'login' }, (!!(config && config.caller) && { 'ext-caller': config.caller })), (!!(config && config.channel) && { 'ext-channel': config.channel })), (!!(config && config.product) && { 'ext-product': config.product }))
                });
            }
        });
    }
}
