import { Env } from '../auth-web/models/auth-web-config.model';
export var ApiBaseUrl;
(function (ApiBaseUrl) {
    ApiBaseUrl["PROD"] = "https://digitalapi.auspost.com.au";
    ApiBaseUrl["SANDBOX"] = "https://digitalapi.auspost.com.au";
    ApiBaseUrl["VTEST"] = "https://digitalapi-vtest.npe.auspost.com.au";
    ApiBaseUrl["STEST"] = "https://digitalapi-stest.npe.auspost.com.au";
    ApiBaseUrl["PTEST"] = "https://digitalapi-ptest.npe.auspost.com.au";
    ApiBaseUrl["DEV"] = "https://digitalapi-ptest.npe.auspost.com.au";
    ApiBaseUrl["PLAYPEN"] = "https://digitalapi-ptest.npe.auspost.com.au";
    ApiBaseUrl["LOCALHOST"] = "http://localhost:4000";
})(ApiBaseUrl || (ApiBaseUrl = {}));
export const ENV_API_BASE_URL_MAPPING = {
    [Env.PROD]: ApiBaseUrl.PROD,
    [Env.SANDBOX]: ApiBaseUrl.SANDBOX,
    [Env.VTEST]: ApiBaseUrl.VTEST,
    [Env.STEST]: ApiBaseUrl.STEST,
    [Env.PTEST]: ApiBaseUrl.PTEST,
    [Env.DEV]: ApiBaseUrl.DEV,
    [Env.PLAYPEN]: ApiBaseUrl.PLAYPEN,
    [Env.LOCALHOST]: ApiBaseUrl.LOCALHOST
};
export var HttpContentType;
(function (HttpContentType) {
    HttpContentType["APP_JSON"] = "application/json";
})(HttpContentType || (HttpContentType = {}));
export var HttpMethod;
(function (HttpMethod) {
    HttpMethod["GET"] = "GET";
    HttpMethod["DELETE"] = "DELETE";
})(HttpMethod || (HttpMethod = {}));
export const HTTP_DEFAULT_HEADERS = {
    'Content-Type': HttpContentType.APP_JSON,
    Accept: HttpContentType.APP_JSON,
    AP_APP_ID: 'UIIM'
};
