var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { COOKIE_EXPIRE } from '../services/auth-web/auth-web.constants';
export const openUrlInIframe = (url) => __awaiter(void 0, void 0, void 0, function* () {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    const removeIframe = () => document.body.contains(iframe) && document.body.removeChild(iframe);
    return new Promise((resolve, reject) => {
        iframe.onload = () => {
            removeIframe();
            resolve();
        };
        iframe.onerror = () => {
            removeIframe();
            reject(new Error('Failed to load URL'));
        };
        document.body.appendChild(iframe);
    });
});
/**
 * builds a URL from the provided baseUrl and an object of pramaeters
 * @param {string} baseUrl, the base URL to use
 * @param {object} paramsObj, an object of parameters
 * @returns string, encoded URL
 */
export const urlBuilder = (baseUrl, paramsObj) => {
    const url = new URL(baseUrl);
    for (const [key, value] of Object.entries(paramsObj)) {
        url.searchParams.append(key, value);
    }
    return url.href;
};
/**
 * clears local storag entries based on orovided pattern
 * @param {string} pattern, pattern used to match localstorage key
 * @returns void
 */
export const clearLocalstorage = (pattern) => {
    const keysToRemove = Object.keys(localStorage).filter(key => key.includes(pattern));
    keysToRemove.forEach((key) => {
        localStorage.removeItem(key);
    });
};
/**
 * clears all cookies containing the pattern within the name
 * @param {string} pattern, pattern to match in cookie name
 * @returns void
 */
export const clearBrowserCookies = (pattern) => {
    const browserCookies = document.cookie.split('; ').reduce((prev, current) => {
        const [name, ...value] = current.split('=');
        prev[name] = value.join('=');
        return prev;
    }, {});
    const cookiesToRemove = Object.keys(browserCookies).filter(key => key.includes(pattern));
    cookiesToRemove.forEach(key => {
        document.cookie = `${key}${COOKIE_EXPIRE}`;
    });
};
