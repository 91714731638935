var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpError } from './http-error.model';
import { ENV_API_BASE_URL_MAPPING, HTTP_DEFAULT_HEADERS, HttpMethod } from './http.constants';
export class HttpService {
    initialise(env, stubApiBaseUrl) {
        this.apiBaseUrl = stubApiBaseUrl ? stubApiBaseUrl : ENV_API_BASE_URL_MAPPING[env];
    }
    /**
     * Executes a GET request to the specified URL.
     * @param {string} path Partial URL of content to fetch
     * @param {Object} headers optional additional headers config
     * @returns {Promise}
     */
    get(path, headers = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpOptions = this._buildHttpOptions(headers);
            return yield this.fetch(`${this.apiBaseUrl}${path}`, httpOptions);
        });
    }
    /**
     * Executes a DELETE request to the specified URL.
     * @param {string} path Partial URL of content to fetch
     * @param {Object} headers optional additional headers config
     * @returns void
     */
    delete(path) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpOptions = this._buildHttpOptions(undefined, HttpMethod.DELETE);
            yield this.fetch(`${this.apiBaseUrl}${path}`, httpOptions);
        });
    }
    /**
     * Build http options to be sent in the http request.
     * @param {Object} headers Optional, additional headers config
     * @param {string} method Optional, will be 'GET' by default
     * @param {Object} body Optional, the request to be sent
     * @returns {RequestInit}
     */
    _buildHttpOptions(headers = {}, method = HttpMethod.GET, body) {
        return {
            method: method,
            headers: new Headers(Object.assign(Object.assign({}, HTTP_DEFAULT_HEADERS), headers)),
            credentials: 'include',
            body: body ? JSON.stringify(body) : undefined
        };
    }
    /**
     * Fetch url with specified http options and throw error if response is not ok.
     * @param {string} url
     * @param {RequestInit} httpOptions
     * @returns {Promise}
     */
    fetch(url, httpOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(url, httpOptions);
            if (!response.ok) {
                throw yield HttpError.createHttpError(response);
            }
            return response.json().catch(() => ({}));
        });
    }
}
