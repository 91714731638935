var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class HttpError extends Error {
    constructor(response, error) {
        super(`${response.status}: ${response.statusText}`);
        this.url = response.url;
        this.message = `${response.status}: ${response.statusText}`;
        this.status = response.status;
        this.statusText = response.statusText;
        this.error = error;
        // needed because otherwise instanceof does not work when transpiling to ES5
        Object.setPrototypeOf(this, HttpError.prototype);
    }
    static createHttpError(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const error = yield response
                .json()
                .then(payload => (payload && payload instanceof Array ? payload[0] : payload))
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                .catch(() => { });
            return new HttpError(response, error);
        });
    }
}
