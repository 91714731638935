export var Env;
(function (Env) {
    Env["PROD"] = "prod";
    Env["SANDBOX"] = "sandbox";
    Env["VTEST"] = "vtest";
    Env["STEST"] = "stest";
    Env["PTEST"] = "ptest";
    Env["DEV"] = "dev";
    Env["PLAYPEN"] = "playpen";
    Env["LOCALHOST"] = "localhost";
})(Env || (Env = {}));
export function getEnvByValue(value) {
    var _a;
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (_a = Object.entries(Env).find(([key, val]) => val === value)) === null || _a === void 0 ? void 0 : _a[0];
}
