import { getEnvByValue } from '../auth-web/models/auth-web-config.model';
import * as Regex from './validator.constants';
export class ValidatorService {
    validateAuthWebConfig(authWebConfig) {
        this._validateClientId(authWebConfig.clientId);
        this._validateEnv(authWebConfig.env);
        this._validateRedirectUrl(authWebConfig.redirectUrl, 'authWebConfig.redirectUrl');
        /**
         * the following properties caller, channel and product are mandatory in the docs (README)
         * but we are not enforcing it yet till all the apps have started providing them
         */
        authWebConfig.caller && this._validateReportingProperty(authWebConfig.caller, 'authWebConfig.caller');
        authWebConfig.channel && this._validateReportingProperty(authWebConfig.channel, 'authWebConfig.channel');
        authWebConfig.product && this._validateReportingProperty(authWebConfig.product, 'authWebConfig.product');
        authWebConfig.audience && this._validateInvalidChars(authWebConfig.audience, 'authWebConfig.audience');
        authWebConfig.scope && this._validateInvalidChars(authWebConfig.scope, 'authWebConfig.scope');
        authWebConfig.stubDomain && this._validateStubDomain(authWebConfig.stubDomain);
        authWebConfig.stubApiBaseUrl && this._validateStubApiBaseUrl(authWebConfig.stubApiBaseUrl);
    }
    validateLoginConfig(loginConfig) {
        loginConfig.audience && this._validateInvalidChars(loginConfig.audience, 'loginConfig.audience');
        loginConfig.scope && this._validateInvalidChars(loginConfig.scope, 'loginConfig.scope');
        loginConfig.emailAddress && this._validateInvalidChars(loginConfig.emailAddress, 'loginConfig.emailAddress');
        loginConfig.redirectUrl && this._validateRedirectUrl(loginConfig.redirectUrl, 'loginConfig.redirectUrl');
        loginConfig.appState && this._validateAppState(loginConfig.appState);
    }
    validateLogoutUrl(logoutUrl) {
        this._validateInvalidChars(logoutUrl, 'logoutUrl');
        !this._isLocalhostUrl(logoutUrl) && this._validateUrlRegex(logoutUrl, 'logoutUrl');
    }
    validateGetTokenConfig(getTokenConfig) {
        getTokenConfig.audience && this._validateInvalidChars(getTokenConfig.audience, 'getTokenConfig.audience');
        getTokenConfig.scope && this._validateInvalidChars(getTokenConfig.scope, 'getTokenConfig.scope');
    }
    validateTargetUrl(targetUrl) {
        this._validateUrl(targetUrl, 'appState.targetUrl', true);
    }
    _validateUrl(url, name, whitelist = false) {
        if (!this._isLocalhostUrl(url)) {
            this._validateUrlRegex(url, name);
            whitelist && this._validateUrlWhitelist(url, name);
        }
        this._validateTextLength(url, 500, name);
        this._validateInvalidChars(url, name);
    }
    _validateClientId(clientId) {
        if (!clientId) {
            throw new Error('authWebConfig.clientId is required');
        }
        this._validateLetterDigitsDashUnderScore(clientId, 'authWebConfig.clientId');
    }
    _validateEnv(env) {
        if (!env) {
            throw new Error('authWebConfig.env is required');
        }
        else if (!getEnvByValue(env)) {
            throw new Error('authWebConfig.env is invalid');
        }
    }
    _validateRedirectUrl(redirectUrl, name) {
        if (!redirectUrl) {
            throw new Error(`${name} is required`);
        }
        this._validateInvalidChars(redirectUrl, name);
        !this._isLocalhostUrl(redirectUrl) && this._validateUrlRegex(redirectUrl, name);
    }
    _validateStubDomain(stubDomain) {
        if (!Regex.LOCALHOST_DOMAIN.test(stubDomain)) {
            throw new Error('authWebConfig.stubDomain is invalid');
        }
    }
    _validateStubApiBaseUrl(stubApiBaseUrl) {
        if (!this._isLocalhostUrl(stubApiBaseUrl)) {
            throw new Error('authWebConfig.stubApiBaseUrl is invalid');
        }
        this._validateInvalidChars(stubApiBaseUrl, 'authWebConfig.stubApiBaseUrl');
    }
    _validateAppState(appState) {
        appState.caller && this._validateReportingProperty(appState.caller, 'loginConfig.appState.caller');
        appState.channel && this._validateReportingProperty(appState.channel, 'loginConfig.appState.channel');
        appState.product && this._validateReportingProperty(appState.product, 'loginConfig.appState.product');
        appState.signupUrl && this._validateUrl(appState.signupUrl, 'loginConfig.appState.signupUrl', true);
        appState.targetUrl && this.validateTargetUrl(appState.targetUrl);
    }
    _validateReportingProperty(value, name) {
        this._validateTextLength(value, 30, name);
        this._validateLetterDigitsDashUnderScore(value, name);
    }
    // low level validators
    _validateUrlRegex(url, name) {
        if (!Regex.VALID_URL.test(url)) {
            throw new Error(`${name} is invalid`);
        }
    }
    _validateUrlWhitelist(url, name) {
        if (!Regex.URL_WHITELIST.some(reg => reg.test(url))) {
            throw new Error(`${name} domain not whitelisted`);
        }
    }
    _validateInvalidChars(input, name) {
        if (Regex.INVALID_CHARS.some(element => input.toLowerCase().includes(element))) {
            throw new Error(`${name} is invalid`);
        }
    }
    _validateLetterDigitsDashUnderScore(input, name) {
        if (!Regex.LETTER_DIGITS_DASH_UNDERSCORE.test(input)) {
            throw new Error(`${name} is invalid`);
        }
    }
    _validateTextLength(text, limit, name) {
        if (text.length > limit) {
            throw new Error(`${name} length limit exceeded`);
        }
    }
    _isLocalhostUrl(url) {
        return Regex.LOCALHOST_URL.test(url);
    }
}
