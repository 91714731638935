var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Auth0SdkService } from '../auth0-sdk/auth0-sdk.service';
import { HttpService } from '../http/http.service';
import { HttpError } from '../http/http-error.model';
import { GLOBAL_CSSO_AUDIENCE } from '../auth0-sdk/auth0-sdk.constants';
import { CHECK_SESSION, EXCHANGE_SESSION, GET_ACCESS_TOKEN, GET_CUSTOMER_BASIC } from './csso-session.constants';
import { AuthError } from '../auth-web';
export class CssoSessionService {
    constructor(serviceFactory) {
        this.serviceFactory = serviceFactory;
        this.auth0SdkService = serviceFactory.getService(Auth0SdkService.name);
        this.httpService = serviceFactory.getService(HttpService.name);
    }
    initialise(cssoSessionConfig) {
        this.cssoSessionConfig = cssoSessionConfig || {};
    }
    setCssoSessionIfNeeded() {
        return __awaiter(this, void 0, void 0, function* () {
            let loginRequired = false;
            if (this.cssoSessionConfig.skipExchange) {
                return loginRequired;
            }
            if (!(yield this.isCssoSessionValid())) {
                yield this._exchangeTokens().catch((error) => {
                    if (error instanceof HttpError && error.status === 401) {
                        loginRequired = true;
                    }
                    else {
                        throw error;
                    }
                });
            }
            return loginRequired;
        });
    }
    refreshCssoSessionIfNeeded() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.cssoSessionConfig.skipRefresh || this._hasCssoBeenRecentlyCalled()) {
                return;
            }
            yield this._checkSession().catch((error) => {
                if (error instanceof HttpError) {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                    console.warn('CSSO session check failed', error.message);
                }
            });
        });
    }
    logoutCssoSession() {
        return __awaiter(this, void 0, void 0, function* () {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            yield this._logout().catch(() => { }); // We ignore any error coming from logout call
        });
    }
    checkCssoSessionIfNeeded(useCache = false) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.cssoSessionConfig.skipCheck || (useCache && this._hasCssoBeenRecentlyCalled()) || (yield this.isCssoSessionValid());
        });
    }
    getAccessToken() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.accessToken) {
                this.accessToken = (yield this.httpService.get(GET_ACCESS_TOKEN)).accessToken;
            }
            return this.accessToken;
        });
    }
    getCssoCustomer() {
        return __awaiter(this, void 0, void 0, function* () {
            return this._getCustomer().catch((error) => {
                if (error instanceof HttpError && error.status === 401) {
                    throw AuthError.createLoginRequiredError();
                }
                else {
                    throw error;
                }
            });
        });
    }
    isCssoSessionValid() {
        return __awaiter(this, void 0, void 0, function* () {
            const customer = yield this._getCustomer().catch((error) => {
                // If error is 401, it means the user is unauthenticated in CSSO (no obsso cookie),
                // so we will need to exchange an access token for an obsso cookie.
                // Any other kind of error is returned to the consuming app.
                if (!(error instanceof HttpError) || error.status !== 401) {
                    throw error;
                }
            });
            let isCssoSessionValid = false;
            if (customer) {
                // If customer is defined, it means there is a CSSO session
                const auth0User = yield this.auth0SdkService.getUser();
                if (auth0User) {
                    // If auth0User is defined, it means there is an auth0 session
                    isCssoSessionValid = customer.email === auth0User.email;
                }
                else {
                    // This is for 2 way coexistence in case only CSSO session exists
                    isCssoSessionValid = true;
                }
            }
            return isCssoSessionValid;
        });
    }
    isCssoSessionRequired() {
        return !this.cssoSessionConfig.skipCheck;
    }
    _checkSession() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.httpService.get(CHECK_SESSION);
            this.lastRefreshTime = new Date(); // needs to be updated AFTER a successful call to CSSO
        });
    }
    _exchangeTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            const accessToken = yield this.auth0SdkService.getAccessTokenSilently({ authorizationParams: { audience: GLOBAL_CSSO_AUDIENCE } });
            yield this.httpService.get(EXCHANGE_SESSION, { AUTH0_ACCESS_TOKEN: accessToken });
            this.lastRefreshTime = new Date(); // needs to be updated AFTER a successful call to CSSO
        });
    }
    _getCustomer() {
        return __awaiter(this, void 0, void 0, function* () {
            const customer = (yield this.httpService.get(GET_CUSTOMER_BASIC)).customer;
            this.lastRefreshTime = new Date(); // needs to be updated AFTER a successful call to CSSO
            return customer;
        });
    }
    _logout() {
        return __awaiter(this, void 0, void 0, function* () {
            this.lastRefreshTime = undefined;
            yield this.httpService.delete(CHECK_SESSION);
        });
    }
    _hasCssoBeenRecentlyCalled() {
        const minutesSince = this.lastRefreshTime ? Math.floor((Date.now() - this.lastRefreshTime.getTime()) / 1000 / 60) : 10;
        return minutesSince < 3;
    }
}
